import {
    disableBodyScroll,
    enableBodyScroll,
} from '@/helpers/bodyScrollLockWrapper';

import { fadeIn, fadeOut } from '@/helpers/jqry-replace';
import Bugsnag, { PREFIX } from '@/helpers/bugsnag';

let open = false;
let fetchedHandle = null;
let productInfoModalSlider = null;

const defaultFetchUrl = '/products/%handle%/?section_id=product-info-modal';

const selectors = {
    productInfoModal: '[data-product-info-modal]',
    productInfoModalBody: '[data-product-info-body]',
    productInfoModalBodyPlaceholder: '[data-product-info-body-placeholder]',
    swiper: '.swiper',
    nextArrow: '[data-product-info-slider-next]',
    previousArrow: '[data-product-info-slider-prev]',
    scrollbar: '.swiper-scrollbar',
    close: '[data-product-info-close]',
    slider: '[data-product-info-slider]',
    toggle: '[data-toggle-product-info]', 
    ckBuilderOpen: '[x-data="ckBuilder"][data-open="true"]'
};

const attributes = {
    allowScroll: 'data-allow-scroll',
};

function openProductInfoModal() {
    const el = document.querySelector(selectors?.productInfoModal);
    if (!el) {
        return;
    }

    if (open) {
        return;
    }

    fadeIn(el, 400);
    open = true;

    const ckBuilderOpen = document.querySelector(selectors?.ckBuilderOpen);
    if (ckBuilderOpen) {
        return;
    }

    disableBodyScroll(el, {
        allowTouchMove(el) {
            while (el && el !== document?.body) {
                if (el?.getAttribute(attributes?.allowScroll) !== null) {
                    return true;
                }

                el = el?.parentElement;
            }

            return false;
        },
    });
}

function initProductInfoModalContentBody(reverse = false) {
    const el = document.querySelector(selectors?.productInfoModal);
    if (!el) {
        return;
    }

    const body = el.querySelector(selectors?.productInfoModalBody);
    if (!body) {
        return;
    }

    const bodyPlaceholder = el?.querySelector(selectors?.productInfoModalBodyPlaceholder);
    if (!bodyPlaceholder) {
        return;
    }

    bodyPlaceholder.style.display = reverse ? 'block' : 'none';
    body.style.display = reverse ? 'none' : 'block';
}

function closeProductInfoModal() {
    const el = document.querySelector(selectors?.productInfoModal);
    if (!el) {
        return;
    }

    if (!open) {
        return;
    }

    fadeOut(el, 400);
    open = false;

    const ckBuilderOpen = document.querySelector(selectors?.ckBuilderOpen);
    if (ckBuilderOpen) {
        return;
    }

    enableBodyScroll(el);
}

async function initProductInfoModalContent(handle, url = defaultFetchUrl) {
    if (!handle) {
        return;
    }

    const fetchUrl = url?.replaceAll('%handle%', handle);
    if (!fetchUrl) {
        return;
    }

    try {
        const response = await fetch(fetchUrl).then((res) => {
            return res.text();
        });

        if (!response) {
            return;
        }

        const doc = new DOMParser().parseFromString(response, 'text/html');
        if (!doc) {
            return;
        }

        const content = doc.querySelector(selectors?.productInfoModalBody);
        if (!content?.innerHTML) {
            return;
        }

        const contentNode = document.querySelector(selectors?.productInfoModalBody);
        if (!contentNode) {
            return;
        }

        fetchedHandle = handle;

        contentNode.innerHTML = content?.innerHTML || "";

        const slider = contentNode.querySelector(selectors?.slider);
        if (!slider) {
            initProductInfoModalContentBody();
            return;
        }

        const sliderEl = slider?.querySelector(selectors?.swiper);
        if (!sliderEl) {
            initProductInfoModalContentBody();
            return;
        }

        const {
            default: Swiper,
            Navigation,
            Scrollbar,
        } = await import('swiper');

        if (!Swiper) {
            initProductInfoModalContentBody();
            return;
        }

        const nextEl = slider?.querySelector(selectors?.nextArrow);
        const prevEl = slider?.querySelector(selectors?.previousArrow);
        const scrollbarEl = slider?.querySelector(selectors?.scrollbar);

        productInfoModalSlider = new Swiper(sliderEl, {
            slidesPerView: 1,
            spaceBetween: 0,
            maxBackfaceHiddenSlides: 1,
            modules: [
                Navigation,
                Scrollbar
            ],
            navigation: {
                nextEl,
                prevEl,
            },
            scrollbar: {
                el: scrollbarEl,
                draggable: true,
            },
        });

        initProductInfoModalContentBody();
    } catch (error) {
        console.error(error);

        try {
            Bugsnag.notify(new Error(`[${PREFIX}] Unable to fetch product info modal`), (event) => {
                event.severity = 'error';

                event.addMetadata('parsedError', {
                    error,
                    response: error?.response,
                });
            });
        } catch (error) {}
    }
}

document.body.addEventListener('click', (event) => {
    const productInfoButton = event?.target?.closest(selectors?.toggle);
    if (!!productInfoButton) {
        return;
    }

    const productInfoClose = event?.target?.closest(selectors?.close);
    const productInfoBody = event?.target?.closest(selectors?.productInfoModalBody);
    if (productInfoBody && !productInfoClose) {
        return;
    }

    closeProductInfoModal();
});

document.body.addEventListener('click', async (event) => {
    const productInfoButton = event?.target?.closest(selectors?.toggle);
    if (!productInfoButton) {
        return;
    }

    const handle = productInfoButton?.dataset?.toggleProductInfo;
    if (!handle) {
        return;
    }

    event?.preventDefault();

    if (fetchedHandle?.toLowerCase() === handle?.toLowerCase()) {
        openProductInfoModal();
        
        if (!productInfoModalSlider) {
            return;
        }

        productInfoModalSlider?.update();
        return;
    }

    initProductInfoModalContentBody(true);

    await initProductInfoModalContent(handle, productInfoButton?.dataset?.url || defaultFetchUrl);

    openProductInfoModal();
});
